import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import Loader from "../components/Reusable/Loader";
import { TbEdit } from "react-icons/tb";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
// import ShareIcon from "@mui/icons-material/Share";
import { fleetlist } from "../API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { robotActions } from "../redux/slice/robotSlice";
import EditRobot from "../components/Robot/EditRobot"
import DashboardBottomBar from "../components/DashboardBottomBar";
import { storeRobotDetails } from "../redux/Actions";
import { useHasPermissionInAnyFleet} from "../customHooks/PermissionHooks";


function RobotPage(props) {
  const { loading,editRobotModalopen} = useSelector((state) => state.robotReducer);
  const emailId = localStorage.getItem("useremail");
  const [robotlistData, setrobotlistData] = useState();
  const [allRobots, setAllRobots] = useState();
  const [editRobotModalData,setEditRobotModalData] = useState({})
  const navigate = useNavigate();
  const { sidebarcollapse } = useSelector((state) => state.login);
  const hasPermissionToEditRobots =  useHasPermissionInAnyFleet(
    "edit-robot",
  );
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, [navigate]);

  const fetchFleetList = (emailId, token, setrobotlistData, setAllRobots, dispatch) => {
    fleetlist(emailId, token)
      .then((res) => {
        setrobotlistData(res?.data?.fleet);
        console.log("res.data.fleet", res?.data);
        let robots = [];
        res?.data?.fleet.forEach((item) => {
          dispatch(storeRobotDetails(item._id));
          item.robots?.forEach((robot) => robots.push(robot));
        });
        setAllRobots(robots);
      })
      .catch((err) => {
        console.error("Error fetching fleet list:", err);
      });
  };
  useEffect(() => {
    if(!editRobotModalopen){
    fetchFleetList(emailId, token, setrobotlistData, setAllRobots, dispatch);  
    }
  }, [dispatch, editRobotModalopen, emailId, token]);
  
  useEffect(() => {
    fetchFleetList(emailId, token, setrobotlistData, setAllRobots, dispatch);
  }, [dispatch, emailId, token]);
  // const actions = [
  //   { icon: <FileCopyIcon />, name: "Copy" },
  //   { icon: <SaveIcon />, name: "Save" },
  //   { icon: <PrintIcon />, name: "Print" },
  //   { icon: <ShareIcon />, name: "Share" },
  // ];
  // const getrobotStatus = (status) => {
  //   switch (status) {
  //     case "AVAILABLE":
  //       // return <img className="FleetPage_table_status_td_img" src="/assets/images/Stop.svg"/>
  //       // return <p style={{marginBottom:'0px'}}> Active</p>
  //       return <img src="/assets/images/Line-green.svg" alt="" />;
  //     case "UNAVAILABLE":
  //       // return <img src="/assets/images/Line 7.png"/>
  //       // return <p style={{marginBottom:'0px'}}> Inactive</p>
  //       return <img src="/assets/images/Line-red.svg" alt="" />;
  //     case "ERROR":
  //       // return <img src="/assets/images/Line 7.png"/>
  //       return <p style={{ marginBottom: "0px", color: "yellow" }}> Error</p>;
  //     default:
  //       // return <img src="/assets/images/Line 7.png"/>
  //       return <p style={{ marginBottom: "0px", color: "red" }}>{status}</p>;
  //   }
  // };
  const isopen = useSelector((state) => state.dashboardopenReducer);
  // const naviate = useNavigate();

  const handlerobotClick = (itm) => {
    let latitude;
    let longitude;
    robotlistData.map((ttt) => {
      if (ttt.fleetId === itm.fleetId) {
        ttt.robots.map((itmmm) => {
          if (itmmm.robotId === itm.robotId) {
            latitude = itmmm.latitude;
            longitude = itmmm.longitude;
          }
          return null;
        });
      }
      return null;
    });
    navigate(`/robotPage/${itm.robotId}`, {
      state: {
        data: itm,
        latitude: latitude,
        longitude: longitude,
        robotlistData: robotlistData,
        marker: "example",
      },
    });
  };

  // const handleaddrobot = () => {
  //   naviate("/addrobot");
  // };

  // const handleRobotEdit = (itm) => {
  //   navigate(`/editrobot/${itm.robotId}`, { state: { data: robotlistData } });
  // };
  // const handleSelectfleet = (e) => {
  //   setselectfleet(e.target.value);
  // };
  // const handleStatusChange = (e) => {
  //   setstatus(e.target.value);
  // };
  const closeEditModal = () => {

    dispatch(robotActions.setEditRobotModal({ value: false }));
  };

  const getrobotStatustwo = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return (
          <span className="color-red Dashboard_page_robotStatusClass">
            {" "}
            UNAVAILABLE
          </span>
        );
      case "AVAILABLE":
        return (
          <span className="color-green Dashboard_page_robotStatusClass">
            {" "}
            AVAILABLE
          </span>
        );
      case "ERROR":
        return (
          <span className="color-yellow Dashboard_page_robotStatusClass">
            {" "}
            Error
          </span>
        );
      default:
        return (
          <span className="color-red Dashboard_page_robotStatusClass">
            {" "}
            {status}
          </span>
        );
    }
  };
  return (
    <>
      <Header />
      {loading && <Loader />}
      <EditRobot
        isOpen={editRobotModalopen}
        onClose={closeEditModal}
        robot={editRobotModalData}
        fleet={robotlistData}
        // selectedFleet={selectedFleet}
        // userData={editUserModalData}
        // roleOptions={roleOptions}
        // callBack={userRecordsForFleet}
      />


      {/* <div className="Fleet_page_Whole_content_wrapper_main">
        {console.log('robotlistData',robotlistData)}
      </div> */}
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="robotpage"
            />
            <DashboardBottomBar active="robotpage" />
          </div>

          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
              <div className="Dashboard_page_robot_card_heading_wrapper">
                <h4 className="Dashboard_page_Robot_Card_heading">Robots</h4>
              </div>
              {allRobots && allRobots.length === 0 ? (
                <div class="Dasboard_page_individual_robot_box_wrapper">
                  <div class="text-sm text-gray-300 d-flex-center rounded-[8px] p-[14px]">
                    No Robot is assigned to any fleet.
                  </div>
                </div>
              ) : (
                <div className="table_wrapper">
                  <table
                    style={{ width: "100%" }}
                    className="Fleet_page_table_Main_whole"
                  >
                    <thead>
                      <tr>
                        <th> Robot Id</th>
                        <th> Fleet Id</th>
                        <th> Robot Name </th>
                        {/* <th> End Point</th>
                      <th> Date Created</th>
                      <th> SSh End Point</th> */}
                        <th> Status</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {robotlistData?.map((item) => {
                        return item?.robotsDetails?.map((itm, key) => {
                          return (
                            <tr key={key}>
                              <td className="Robot_page_robotId_td">
                                {" "}
                                {itm.robotId}{" "}
                                {getrobotStatustwo(
                                  item?.robots[key]?.robotStatus
                                )}
                                {/* <sup
                                className={
                                  item.robots[key].connectionStatus ===
                                    "OFFLINE"
                                    ? "p-color-red-two"
                                    : "p-color-green-two"
                                }
                              >
                                {" "}
                                {item.robots[key].connectionStatus}{" "}
                              </sup>
                              {item.robots[key].connectionStatus ===
                                "OFFLINE" ? (
                                <span> {item.robots[key].lastUpdatedTime}</span>
                              ) : null} */}
                              </td>
                              <td> {item?.fleetName}</td>
                              <td> {itm?.displayRobotName}</td>
                              {/* <td> {itm.endpoint}</td>
                            <td> {itm.createdAt}</td>
                            <td> {itm.sshEndpoint} </td> */}

                              <td className="FleetPage_table_status_td">
                                {/* {getrobotStatus(item.robots[key].robotStatus)} */}
                                <p
                                  className={
                                    item?.robots[key]?.connectionStatus ===
                                    "OFFLINE"
                                      ? "p-color-red-two"
                                      : "p-color-green-two"
                                  }
                                >
                                  {" "}
                                  {item?.robots[key]?.connectionStatus}{" "}
                                </p>
                                {item?.robots[key]?.connectionStatus ===
                                "OFFLINE" ? (
                                  <span>
                                    {" "}
                                    {item?.robots[key]?.lastUpdatedTime}
                                  </span>
                                ) : null}
                              </td>
                              <td className="FleetPage_table_action_td ">
                              {hasPermissionToEditRobots&&
                                      <TbEdit
                                        className="w-5 h-5 cursor-pointer text-ottonomyBlue"
                                        onClick={() => {
                                          setEditRobotModalData(itm);
                                          dispatch(robotActions.setEditRobotModal({ value: true }));
                                        }}
                                      / >}

                                <img
                                  className="cursor_pointer"
                                  onClick={() => handlerobotClick(itm)}
                                  src="/assets/images/arrow-link-icon.svg"
                                  alt=""
                                />
                                {/* <img className="cursor_pointer" src="/assets/images/b.svg" onClick={()=>handleRobotEdit(itm)}/> */}
                                {/* <img
                                className="cursor_pointer"
                                src="/assets/images/Deleteiconlist.svg"
                              /> */}
                              </td>
                            </tr>
                          );
                        });
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <button className="FleetPage_plusIcon" onClick={handleaddrobot}>
        <AddIcon />
      </button> */}
    </>
  );
}

export default RobotPage;
