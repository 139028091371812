import React, { Fragment, useState } from "react";
import { IoCloseSharp, IoEyeOutline } from "react-icons/io5";

const CustomAccordion = ({
  order,
  orderStatusColor,
  orderIndex,
  chartOrderData,
  customFormatTime,
  fleetData,
  locationPositionValuePriority,
}) => {
  const [distanceUnit, setDistanceUnit] = useState("KMS")
  const [isActive, setIsActive] = useState(false);
  const [timeZone, setOrderTimeZone] = useState("America/New_York")
  const calculateOrderDistance = (order) => {
    if (order.hasOwnProperty("orderDistance")) {
      switch(distanceUnit){
        case "miles":
            return (order.orderDistance * 0.00062137).toFixed(2);
        case "KMS":
            return (order.orderDistance * 0.001).toFixed(2);
        default:
            return (order.orderDistance * 0.001).toFixed(2);
      }
    } else if (order.hasOwnProperty("orderTime")) {
      return (order.orderTime * 0.6).toFixed(2);
    } else {
      return 0;
    }
  };

  return (
    <>
      <tr
        className={`${isActive && "bg-[#4B505F]"} rounded-t-md ${
          orderIndex !== chartOrderData.length - 1 &&
          !isActive &&
          "border-b border-[rgba(255,255,255,0.16)]"
        }`}
      >
        <td className="p-3 rounded-tl-md">{order.robotId}</td>
        <td className="p-3">{order.orderId}</td>
        <td className="p-3 font-semibold capitalize">
          {orderStatusColor(order.orderStatus, order.statusCode)}
        </td>
        <td className="p-3">{order.cabinId}</td>
        {fleetData?.booking?.pickup &&
          fleetData?.booking?.pickup.length !== 0 && (
            <td className="p-3">
              {locationPositionValuePriority(order?.pickup)}
            </td>
          )}
        {fleetData?.booking?.delivery &&
          fleetData?.booking?.delivery.length !== 0 && (
            <td className="p-3">
              {locationPositionValuePriority(order?.delivery)}
            </td>
          )}
        {/* <td className="p-3"></td> */}
        <td className="p-3">
          {new Date(order.createdAt).toLocaleString("en-US", {
            timeZone : order?.timeZone ?? "America/New_York",
            timeZoneName: "short"
          })}{" "} 
        </td>
        <td align="center" className="p-3 rounded-tr-md">
          {!isActive ? (
            <IoEyeOutline
              className="w-5 h-5 cursor-pointer text-[#00B7D4]"
              onClick={() => setIsActive(true)}
            />
          ) : (
            <IoCloseSharp
              className="w-5 h-5 cursor-pointer text-[#E96C67]"
              onClick={() => setIsActive(false)}
            />
          )}
        </td>
      </tr>
      {isActive && (
        <>
          <tr className="relative shadow-[inset_0px_0px_0px_12px_#4B505F] rounded-b-md">
            <td className="relative p-4 align-top before:absolute before:h-[calc(100%-24px)] before:w-2 before:bg-[#282f43] before:top-3 before:left-2 before:rounded-l-md">
              <div className="flex flex-col">
                <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                  Total Time
                </span>
                <span className="text-xl text-[#02B7D4] font-bold">
                  {order.hasOwnProperty("orderTime")
                    ? customFormatTime(order?.orderTime) !== ""
                      ? customFormatTime(order?.orderTime)
                      : "0 sec"
                    : "0 sec"}{" "}
                </span>
              </div>
            </td>
            <td
              colSpan={order.ETA ? 4 : 5}
              className="p-4 border-x border-x-[#4B505F] align-top"
            >
              <div className="flex items-start gap-2 justify-evenly">
                {order?.pickupTransitTime ? (
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Pickup Time
                    </span>
                    <span className="text-xl text-[#02B7D4] font-bold">
                      {order.hasOwnProperty("pickupTransitTime")
                        ? customFormatTime(order?.pickupTransitTime) !== ""
                          ? customFormatTime(order?.pickupTransitTime)
                          : "0 sec"
                        : "0 sec"}{" "}
                    </span>
                  </div>
                ) : null}
                {order?.pickupWaitTime ? (
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Pickup Wait Time
                    </span>
                    <span className="text-xl text-[#02B7D4] font-bold">
                      {order.hasOwnProperty("pickupWaitTime")
                        ? customFormatTime(order?.pickupWaitTime) !== ""
                          ? customFormatTime(order?.pickupWaitTime)
                          : "0 sec"
                        : "0 sec"}{" "}
                    </span>
                  </div>
                ) : null}
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Delivery Time
                  </span>
                  <span className="text-xl text-[#02B7D4] font-bold">
                    {order.hasOwnProperty("dropoffTransitTime")
                      ? customFormatTime(order.dropoffTransitTime) !== ""
                        ? customFormatTime(order?.dropoffTransitTime)
                        : "0 sec"
                      : "0 sec"}{" "}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Delivery Wait Time
                  </span>
                  <span className="text-xl text-[#02B7D4] font-bold">
                    {order.hasOwnProperty("dropoffWaitTime")
                      ? customFormatTime(order?.dropoffWaitTime) !== ""
                        ? customFormatTime(order?.dropoffWaitTime)
                        : "0 sec"
                      : "0 sec"}{" "}
                  </span>
                </div>
              </div>
            </td>
            {order.ETA ? (
              <td className="p-4 border-r border-r-[#4B505F] ">
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Estimated Delivery Time
                  </span>
                  <div className="flex items-center gap-1">
                    {/* <span className="h-[6px] w-[18px] bg-[#E96C67] rounded-full"></span>{" "} */}
                    <span
                      className={`text-xl ${
                        order?.ETA > order?.orderTime
                          ? "text-[#71cf62]"
                          : "text-[#E96C67]"
                      } font-bold`}
                    >
                      {order.hasOwnProperty("ETA")
                        ? customFormatTime(order?.ETA) !== ""
                          ? customFormatTime(order?.ETA)
                          : "0 sec"
                        : "0 sec"}{" "}
                    </span>
                  </div>
                </div>
              </td>
            ) : null}
            <td className="relative p-4 after:absolute after:h-[calc(100%-24px)] after:w-2 after:bg-[#282f43] after:top-3 after:right-2 after:rounded-r-md">
              <div className="flex flex-col">
                <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                  Total Distance
                </span>
                <span className="text-xl text-[#02B7D4] font-bold">
                  {calculateOrderDistance(order)}
                  {" "+distanceUnit}
                </span>
              </div>
            </td>
          </tr>
          {order.hasOwnProperty("orderInfo")&&
          <tr className="relative shadow-[inset_0px_0px_0px_12px_#4B505F] rounded-b-md">
            <td
              colSpan={7}
              className="p-4 border-x border-x-[#4B505F] align-top before:absolute before:h-[calc(100%-24px)] before:w-2 before:bg-[#282f43] before:top-3 before:left-2 before:rounded-l-md after:absolute after:h-[calc(100%-24px)] after:w-2 after:bg-[#282f43] after:top-3 after:right-2 after:rounded-r-md"
            >
              <div className="flex items-start gap-2 justify-evenly">
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Total Orders
                  </span>
                  <span className="text-xl text-[#02B7D4] font-bold">
                    {order.hasOwnProperty("orderInfo")&&order.orderInfo.hasOwnProperty("analyticsdata")&&order.orderInfo.analyticsdata.hasOwnProperty("numberoforder")
                      ? order.orderInfo.analyticsdata.numberoforder
                      : "0"}{" "}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Total Bottles
                  </span>
                  <span className="text-xl text-[#02B7D4] font-bold">
                  {order.hasOwnProperty("orderInfo")&&order.orderInfo.hasOwnProperty("analyticsdata")&&order.orderInfo.analyticsdata.hasOwnProperty("numberofbottles")
                      ? order.orderInfo.analyticsdata.numberofbottles
                      : "0"}{" "}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          }
          {/* <tr className="shadow-[inset_0px_0px_0px_12px_#4B505F] rounded-b-md !bg-[#35394a]">
            <td
              colSpan={3}
              className="relative p-4 align-middle before:absolute before:h-[calc(100%-24px)] before:w-2 before:bg-[#35394a] before:top-3 before:left-2 before:rounded-bl-md "
            >
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                    25 min
                  </span>
                  <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                    <span className="h-1.5 w-5 bg-[#ECAE3F] "></span>
                    <span>Autonomous</span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                    10 min
                  </span>
                  <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                    <span className="h-1.5 w-5 bg-[#EA515A] "></span>
                    <span>Teleoperated</span>
                  </div>
                </div>
              </div>
            </td>
            <td colSpan={3} className="relative px-5 py-4 align-bottom">
              <span className="absolute w-[1px] h-full -top-3 left-0 bg-[#4B505F]"></span>
              <span className="absolute w-[1px] h-full -top-3 right-0 bg-[#4B505F]"></span>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                    0.4 miles
                  </span>
                  <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                    <span className="h-1.5 w-5 bg-[#ECAE3F] "></span>
                    <span>Autonomous</span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                    0.1 miles
                  </span>
                  <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                    <span className="h-1.5 w-5 bg-[#EA515A] "></span>
                    <span>Teleoperated</span>
                  </div>
                </div>
              </div>
            </td>
            <td
              colSpan={2}
              className="relative py-4 px-5 align-middle after:absolute after:h-[calc(100%-24px)] after:w-2 after:bg-[#35394a] after:top-3 after:right-2 after:rounded-br-md "
            >
              <div className="flex flex-col">
                <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                  5
                </span>
                <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                  <span className="h-1.5 w-5 bg-[#02B7D4] "></span>
                  <span>No. of Intervention</span>
                </div>
              </div>
            </td>
          </tr> */}
        </>
      )}
    </>
  );
};

export default CustomAccordion;
